* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactTable {
  height: 400px;
}

.pagination-bottom {
  display: none;
}

.rt-body {
  min-width: unset;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.quick-view-modal {
  padding: 0px !important;
  left: 25% !important;
  /* top: 20% !important; */
  top: 4% !important;
  overflow: auto !important;
}

.react-multi-carousel-dot-list {
  text-align: center;
  width: 328px;
  margin-left: 42%;
}

.react-multi-carousel-item {
  /* width: 328px !important; */
  text-align: center;
}

.react-multi-carousel-item img {
  width: 89% !important;
}

.react-multi-carousel-item--active {
  width: 328px !important;
}

.custom-dot {
  display: inline-block;
}

.react-multi-carousel-item {
  display: inline-block;
}

.react-multi-carousel-list {
  width: 100% !important;
}

.carousel-with-custom-dots {
  padding-bottom: 100px;
}
.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, transition;
  overflow: visible !important;
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-item {
  flex-shrink: 0 !important;
}

.bPOmmW {
  max-width: 300px !important;
}
.hThAbr {
  font-size: 19px !important;
  /* line-height: 1.2 !important; */
}
.ant-popover-inner {
  border-radius: 10px;
}
@media (max-width: 1280px) {
  .quick-view-modal {
    left: 15% !important;
    /* top: 18% !important; */
    top: 4% !important;
  }
}

@media (max-width: 1300px) {
  .responsive {
    right: 165px;
    /* right: 165px !important; */
  }
}
